<!-- eslint-disable -->
<template>
  <div class="flex h-full w-full relative">
    <div class="z-10 flex absolute top-0 right-4">
      <div class="flex items-center p-2 mr-1 cursor-pointer" @click="reloadGraph">
        <span v-show="getNeedGraphUpdate" class="dot-mark dot-mark--reload" />
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 512 512">
        <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" fill='var(--theme-color-main)'/></svg>
      </div>
    </div>
    <div class="flex flex-col mr-2" v-show="!this.childComponent">
      <div title="Filter">
        <button
          :disabled="this.loaderProgress != 100"
          class="collapse_btn"
          :class="
            this.activeSection == 'filter' ? 'btn-color' : this.selectedFilters.node_type.length > 5 ? 'btn-color' : ''
          "
          @click="toggleSection('filter')"
        >
          <span v-show="filterApplied" class="dot-mark dot-mark--filter" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            :fill="this.activeSection == 'filter' ? '#ffffff'  :  ''"
          >
            <path
              d="M14.4994 12V14H13.166V12H11.8327V10.6667H15.8327V12H14.4994ZM3.83268 12V14H2.49935V12H1.16602V10.6667H5.16602V12H3.83268ZM7.83268 4V2H9.16602V4H10.4994V5.33333H6.49935V4H7.83268ZM7.83268 6.66667H9.16602V14H7.83268V6.66667ZM2.49935 9.33333V2H3.83268V9.33333H2.49935ZM13.166 9.33333V2H14.4994V9.33333H13.166Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      <div title="Depth">
        <button
          :disabled="this.loaderProgress != 100"
          class="collapse_btn"
          :class="
            this.activeSection == 'depth'
              ? 'btn-color'
              : this.nodeConnectionCount != 3
              ? 'btn-color'
              : ''
          "
          @click="toggleSection('depth')"
        >
          <span v-show="applyDepth" class="dot-mark dot-mark--depth" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18.03"
            :fill="this.activeSection == 'depth' ? '#ffffff'  :  ''"
          >
            <path
              d="M8 1.5C8.41423 1.5 8.75 1.83579 8.75 2.25V5.25C8.75 5.66421 8.41423 6 8 6H6.5V7.5H10.25V6.75C10.25 6.33579 10.5858 6 11 6H15.5C15.9142 6 16.25 6.33579 16.25 6.75V9.75C16.25 10.1642 15.9142 10.5 15.5 10.5H11C10.5858 10.5 10.25 10.1642 10.25 9.75V9H6.5V13.5H10.25V12.75C10.25 12.3358 10.5858 12 11 12H15.5C15.9142 12 16.25 12.3358 16.25 12.75V15.75C16.25 16.1642 15.9142 16.5 15.5 16.5H11C10.5858 16.5 10.25 16.1642 10.25 15.75V15H5.75C5.33579 15 5 14.6642 5 14.25V6H3.5C3.08579 6 2.75 5.66421 2.75 5.25V2.25C2.75 1.83579 3.08579 1.5 3.5 1.5H8ZM14.75 13.5H11.75V15H14.75V13.5ZM14.75 7.5H11.75V9H14.75V7.5ZM7.25 3H4.25V4.5H7.25V3Z"
              fill=""
            />
          </svg>
        </button>
      </div>
      <div title="Combine">
        <button
          :disabled="this.loaderProgress != 100"
          class="collapse_btn"
          :class="
            this.activeSection == 'combined'
              ? 'btn-color'
              : this.selectedCombineByNodes
              ? 'btn-color'
              : ''
          "
          @click="toggleSection('combined')"
        >
          <span v-show="applyCombined" class="dot-mark dot-mark--combined" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            :fill="this.activeSection == 'combined' ? '#ffffff' : ''"
          >
            <path
              d="M5.82881 6.59243C6.08884 7.54763 6.9624 8.25 8 8.25H11C12.8527 8.25 14.3917 9.59363 14.6956 11.3596C15.5977 11.6525 16.25 12.5001 16.25 13.5C16.25 14.7427 15.2427 15.75 14 15.75C12.7573 15.75 11.75 14.7427 11.75 13.5C11.75 12.55 12.3387 11.7376 13.1712 11.4076C12.9111 10.4524 12.0376 9.75 11 9.75H8C7.15579 9.75 6.37674 9.47107 5.75 9.0003V11.378C6.62389 11.6869 7.25 12.5203 7.25 13.5C7.25 14.7427 6.24264 15.75 5 15.75C3.75736 15.75 2.75 14.7427 2.75 13.5C2.75 12.5203 3.37611 11.6869 4.25 11.378V6.62197C3.37611 6.3131 2.75 5.47967 2.75 4.5C2.75 3.25736 3.75736 2.25 5 2.25C6.24264 2.25 7.25 3.25736 7.25 4.5C7.25 5.44996 6.6613 6.26241 5.82881 6.59243ZM5 5.25C5.41421 5.25 5.75 4.91421 5.75 4.5C5.75 4.08579 5.41421 3.75 5 3.75C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25ZM5 14.25C5.41421 14.25 5.75 13.9142 5.75 13.5C5.75 13.0858 5.41421 12.75 5 12.75C4.58579 12.75 4.25 13.0858 4.25 13.5C4.25 13.9142 4.58579 14.25 5 14.25ZM14 14.25C14.4142 14.25 14.75 13.9142 14.75 13.5C14.75 13.0858 14.4142 12.75 14 12.75C13.5858 12.75 13.25 13.0858 13.25 13.5C13.25 13.9142 13.5858 14.25 14 14.25Z"
              fill=""
            />
          </svg>
        </button>
      </div> 
      <div class="hidden">
        <button
          :disabled="this.loaderProgress != 100"
          class="collapse_btn"
          :class="this.activeSection == 'download'? 'btn-color' : ''"
          @click="toggleSection('download')"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 20 24" id="download"  
            width="19"
            height="18">
            <path 
              d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z">
            </path>
          </svg>
        </button>
      </div> 
    </div>

    <div  class="flex"  v-show="this.activeSection === 'filter' && !this.childComponent">
      <div v-show="showOptionsPanel && expandedGraph"  class="relative flex flex-col graph--options w-72 mr-2 overflow-x-hidden">
        <div class="flex flex-col rounded-lg py-4 px-2 mx-1 bg-white shadow-md">
          <div>
            <div
              class="text-sm text-gray-500 flex justify-between cursor-pointer"
              :class="toolType.length > 0 ? ['highlight--filters'] : ''"
            >
              <div
                v-if="toolType.length > 0"
                class="restore--icon text-xs"
                @click="
                  () => {
                    this.selectedFilters = {
                      node_type: [],
                      risk_only: false,
                    };
                    this.updateKeylineData(this.graphData);
                  }
                "
              ></div>
            </div>
            <div>
              <div class="filter-item">
                <label>Node Type</label>
                <vue-multiselect
                  :key="this.filterRender"
                  :options="nodeTypesList"
                  v-model="selectedFilters['node_type']"
                  :showLabels="false"
                  openDirection="bottom"
                  placeholder="Select filter"
                  :searchable="true"
                  :multiselect="true"
                  :multiple="true"
                  :checkboxes="true"
                  :close-on-select="false"
                  :limit="1"
                  class="var(--area-brand-color)"
                ></vue-multiselect>
              </div>
            </div>

            <div>
              <div class="filter-item">
                <label>Has Risk</label>
                <vue-multiselect
                  :options="this.hasRisk"
                  v-model="selectedFilters['risk_only']"
                  :showLabels="false"
                  openDirection="bottom"
                  placeholder="Select filter"
                  :searchable="true"
                  :multiselect="false"
                  :multiple="false"
                  :checkboxes="true"
                  :limit="1"
                  class="var(--area-brand-color)"
                ></vue-multiselect>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <button
            @click="applyFilter('filter')"
            class="btn btn-wide btn-primary mt-3"
            :class="{ loading: loadingFilter }"
          >
            <span>Apply Filter</span>
          </button>

          <button
            @click="resetFilter('filter')"
            class="btn bg-white border border-blue-500 text-blue-500 px-4 py-2 rounded mt-3"
            :class="{ loading: loading }"
          >
            <span>Reset</span>
          </button>
        </div>
      </div>
    </div>

    <div  class="flex"  v-show="this.activeSection === 'depth' && !this.childComponent">
      <div
        v-show="showOptionsPanel && expandedGraph"
        class="relative flex flex-col graph--options w-72 mr-2 overflow-x-hidden"
      >
        <div class="flex flex-col rounded-lg px-2 py-4 mx-1 bg-white shadow-md">
          <div class="">
            <div
              class="text-sm text-black-400 mb-2"
              :class="nodeDegree ? ['highlight--filters'] : ''"
            >
              Connection depth
            </div>
            <div class="flex justify-around items-center mx-8">
              <button
                class="btn btn-square btn-sm fs-4"
                :disabled="nodeConnectionCount == 0"
                @click="updateCount(-1)"
              >
                -
              </button>
              <input
                type="text"
                placeholder=""
                disabled
                :value="nodeConnectionCount"
                class="input input-sm input-bordered w-20 text-center"
              />
              <button
                class="btn btn-square btn-sm fs-8"
                :disabled="disableAddDepthBtn"
                @click="updateCount(1)"
              >
                +
              </button>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            @click="applyFilter('depth')"
            class="btn btn-wide btn-primary mt-3"
            :class="{ loading: loadingDepth }"
          >
            <span>Apply Filter</span>
          </button>
          <button
            @click="resetFilter('depth')"
            class="btn bg-white border border-blue-500 text-blue-500 px-4 py-2 rounded mt-3"
            :class="{ loading: loading }"
          >
            <span>Reset</span>
          </button>
        </div>
      </div>
    </div>

    <div  class="flex"  v-show="this.activeSection === 'combined' && !this.childComponent">
      <div
        v-show="showOptionsPanel && expandedGraph"
        class="relative flex flex-col graph--options w-72 mr-2 overflow-x-hidden"
      >
        <div class="flex flex-col rounded-lg px-2 py-4 mx-1 bg-white shadow-md">
          <div class="mt-2">
            <div class="filter-item">
              <label>Combined by </label>
              <vue-multiselect
                :options="allowedCombineByNodeType"
                v-model="selectedCombineByNodes"
                :showLabels="false"
                openDirection="bottom"
                placeholder="Select Node"
                :searchable="false"
                multiselect
                multiple
                checkboxes
                :limit="1"
                class="var(--area-brand-color)"
              ></vue-multiselect>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <button
              :disabled="!selectedCombineByNodes?.length"
              class="btn btn-wide btn-primary mt-3"
              :class="{ loading: loadingCombined }"
              @click="combineNodes"
            >
              <span>Apply Combine</span>
            </button>
            <button
              :disabled="!selectedCombineByNodes?.length"
              class="btn bg-white border border-blue-500 text-blue-500 px-4 py-2 rounded mt-3"
              :class="{ loading: loading }"
              @click="resetFilter('combined')"
            >
              <span>Reset</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div  class="flex"  v-show="this.activeSection === 'download' && !this.childComponent">
      <div v-show="showOptionsPanel && expandedGraph"  class="relative flex flex-col graph--options w-72 mr-2 overflow-x-hidden">
        <div class="flex flex-col rounded-lg px-2 py-4 mx-1 bg-white shadow-md">
          <form autocomplete="off" @submit.prevent="exportChartImage" id="rhsForm">
              <div class="cicontent">
                <div class="media-body">
                  <div class="font-bold mb-4">Type</div>
                  <div class="flex justify-evenly" id="exportTypes">
                    <input v-for="(button, exportType) in exportTypes" :key="exportType" :class="button.classes" :type="button.type" :value="exportType" :disabled="button.disabled" @click="setExportType(exportType)">
                  </div>
                  <h4 id="exportContent" class="mt-4 font-bold">Content extents</h4>
                  <label class=" inline" v-for="(extentsLabel, extentsValue) in extentsLabels" :key="extentsValue">
                    <input type="radio" name="extents" :value="extentsValue" v-model="extentsValue"> {{ extentsLabel }}
                  </label>
                  <div class="mt-4">
                    <span class="font-bold">Image resolution: </span><span id="widthLabel">{{ width }}</span>k pixels
                    <div class="flex justify-between items-center">
                    <div class="sliderScale">1k</div>
                    <div class="">
                      <input id="widthSlider" type="range" min="1000" max="5000" v-model="width">
                    </div>
                    <div class="sliderScale">5k</div>
                    </div>
                  </div>
                  <input class="btn btn-wide btn-primary mt-3" type="button" value="Export Image" style="width: 100%; margin-top: 10px;" @click="exportChartImage">
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  

    <div class="flex relative h-full w-full">
      <div
        v-if="layoutDone"
        class="bg-drop flex justify-center items-center bg-white w-full h-full absolute z-20"
      >
        <Loader :size="8" class="mx-auto my-3" />
      </div>

      <div class="w-full">
        <KlChart
          style="height: 100%; width: 100%"
          :id="`kl_${Date.now()}`"
          containerClass="klchart"
          :data="keylineData"
          @kl-ready="klReady"
          :ready="loadedChart"
        />
      </div>
    </div>
    <div class="flex relative unselectable">
      <CanvasMenu
        class="offcanvasFilter cursor-pointer"
        :isMainenity="this.isMainenity"
        :individualData="this.individualData"
        :mainEntity="mainEntity"
        ref="canvasMenu"
        :graph="true"
        @updateIsShow="(i) => {this.detailsClose(i)}"
        :isShow="this.showCanvas"
        :sourceName="sourceName"
      />
    </div>
  </div>
</template>

<script>
//  eslint-disable
import { mapGetters, mapMutations } from "vuex";
import KeyLines from "keylines";
import KlChart from "@shared/light-ray/components/Chart.vue";
import VueMultiselect from "@shared/light-ray/components/graph/vue-multiselect";
import KeyLinesEnums from "../graphEnums.json";
import { getEntityIdentifiers, getinternetDataDetails} from "../../../services";
import CanvasMenu from "../../../components/canvas-menu";
import Loader from "@/components/loader";
import {
  NODE_TYPES,
  NODE_TYPE_FILTER_TO_PAYLOAD_MAP,
  LINK_COLOR,
  DEFAULT_CONNECTION_COUNT,
  DEFAULT_NODE_TYPES,
  COMBINED_TYPES,
  COMBINED_NODE_TYPE_TO_FA_ICON_MAP,
  imageAlignmentDefinitions,
} from "../constants";

export default {
  name: "KlGraphNew",
  components: {
    KlChart,
    "vue-multiselect": VueMultiselect,
    CanvasMenu,
    Loader,
  },
  props: [
    "graphData",
    "maxGraphDepth",
    "readOnly",
    "expandedGraph",
    "childComponent",
    "showOptionsPanel",
    "caseId",
    "mainEntity",
    "loaderProgress",
  ],
  data() {
    return {
      isMainenity:false,
      sourceName:'',
      showCanvas: false,
      keylineData: {},
      keyLinesEnums: KeyLinesEnums,
      rootFilterNodes: ["node_type", "risk_only"],
      hasRisk: ["true", "false"],
      listNodeTypes: [],
      selectedFilters: {
        node_type: DEFAULT_NODE_TYPES,
        risk_only: false,
      },
      filterRender : 0,
      keylineFilterData: {},
      firstGraphData: null,
      graphDataNew: null,
      chart: null,
      graph:null,
      iconMap: {},
      filterCollpse: true,
      filterApplied: false,
      applyCombined: false,
      applyDepth: false,
      loadingFilter: false,
      loadingDepth: false,
      loadingCombined: false,
      loading: false,
      activeSection: null,
      toolType: [],
      nodeConnectionCount: DEFAULT_CONNECTION_COUNT,
      noOfHops:1,
      possibleSearchTypes: [],
      contains_all: false,
      layoutDone: false,
      allowedCombineByNodeType: [COMBINED_TYPES.Person, COMBINED_TYPES.Country],
      selectedCombineByNodes: null,
      nodeDegree: 1,
      individualData: null,
      chartData: {
        time: 1000,
        mode: "adaptive",
        easing: "linear",
      },
      type: 'png',
      width: 3,
      exportTypes: {
        png: { type: 'button', classes:  ['btn btn-sm active-btn'],  },
        jpeg: { type: 'button', classes: ['btn btn-sm'],  },
        svg: { type: 'button', classes:  ['btn btn-sm'],  },
        // pdf: { type: 'button', classes:  ['btn btn-sm'], disabled: false },
      },
       extentsLabels: {
        view: 'Current view',
        chart: 'Whole chart',
      },
      extents: 'view',
    };
  },

  created() {
    this.toolType = [{ id: "company", label: "Company Records" }];
  },

  async mounted() {
    this.firstGraphData = this.graphData;
    this.updateKeylineData(this.graphData);
    // this.setSelectedFilter(this.graphData);
    console.log(this.graphData)
  },
  computed: {
    ...mapGetters(["getNeedGraphUpdate"]),

    nodeTypesList() {
      return NODE_TYPES;
    },
    mappedNodeTypes() {
      return this.selectedFilters?.node_type?.map(t => {
        return NODE_TYPE_FILTER_TO_PAYLOAD_MAP.has(t) ? NODE_TYPE_FILTER_TO_PAYLOAD_MAP.get(t) : t;
      });
    },
    constructedPayload() {
      return {
        ...(this.selectedFilters?.node_type?.length > 0 ? { node_type: this.mappedNodeTypes } : {}),
        ...(this.nodeConnectionCount ? { depth: this.nodeConnectionCount } : {}),
        risk_only: this.selectedFilters?.risk_only,
        contains_all: this.contains_all,
      };
    },
    disableAddDepthBtn() {
      return this.nodeConnectionCount >= this.maxGraphDepth;
    },
  },

  watch: {
    graphData: {
      async handler(newValue, oldValue) {
        if(newValue != oldValue){
          if(this.chart) {
            if(this.sourceName == 'opencorporates'){
              const addedItems = newValue.filter((newItem) => !oldValue.some((oldItem) => newItem.id === oldItem.id));
              await this.chart.expand(addedItems, { animate: true, layout: { fit: true } })
            }else{
              await this.updateKeylineData(newValue); 
            }
            await this.klReady(this.chart);
            if(this.applyCombined){
              this.combineNodes();
            }
          } 
           
        }
      },
      deep: true 
    },
    selectedFilters: {
      async handler(newValue, oldValue) {
        if (newValue != oldValue) {
          ++this.filterRender;
        }
      },
      deep: true,
    }
  },
  methods: {
    ...mapMutations(["RESET_NEED_GRAPH_UPDATE"]),

    async detailsClose(i){
      this.showCanvas = i.graphClose;
      this.individualData = {};
      this.isMainenity = false;

      if (i.sourceName == "opencorporates") {
        this.$emit("graphUpdate", this.constructedPayload);
        this.sourceName = "";
      }
    },
    setExportType(newType) {
      for (const exportType in this.exportTypes) {
        this.exportTypes[exportType].classes = ['btn btn-sm'];
        // this.exportTypes[exportType].disabled = false;
      }
      this.exportTypes[newType].classes.push('active-btn');
      // this.exportTypes[newType].disabled = true;
      this.type = newType;
    },
    setWidthLabel() {
      const width = Math.round(this.width / 100) / 10;
      this.$refs.widthLabel.innerText = `${width}`;
    },
    downloadImage(chartImage, format) {
      const snapshotLink = document.createElement('a');
      snapshotLink.download = `${this.caseId}chart-export.${format}`;
      snapshotLink.href = chartImage.url;
      snapshotLink.click();
      URL.revokeObjectURL(chartImage.url);
    },
    async exportChartImage() {
      const fitTo = this.type === 'pdf' ? 'page' : { width: this.type === 'svg' ? this.chart.viewOptions().width : parseInt(this.width) };
      const fonts = { 'Font Awesome 5 Free Solid': { src: '/fonts/fontAwesome5/fa-solid-900.woff' } };
      const doc = { size: 'letter', layout: 'landscape', margin: 36 };
      const chartImage = await this.chart.export({ type: this.type, extents: this.extents, fitTo, ...(this.type === 'svg' || this.type === 'pdf' ? { fonts } : {}), ...(this.type === 'pdf' ? { doc } : {}) });
      this.downloadImage(chartImage, this.type);
    },
    initializeUI() {
      ['png', 'jpeg', 'svg', 'pdf'].forEach(exportType => {
        this.$set(this.exportTypes, exportType, this.$refs[exportType]);
      });
      this.$refs.widthSlider.addEventListener('input', this.setWidthLabel);
    },
    async reloadGraph () {
      this.layoutDone = true;
      this.$emit("graphUpdate", this.constructedPayload);
      this.layoutDone = false;
      this.RESET_NEED_GRAPH_UPDATE();
    },

    loadedChart(newChart) {
      this.chart.current = newChart;
    },

    async updateKeylineData(graphData) {
      this.layoutDone = true;
      if (graphData) {
        this.keylineData = { type: "LinkChart", items: graphData };
        // only excute on filter and combined
        if (this.chart) {
          this.chart.load(this.keylineData);
        }
        this.layoutDone = false;
      }
    },

    updateCount(value) {
      if (value === -1 && this.nodeConnectionCount === 0) {
        return;
      }
      this.nodeConnectionCount += value;
    },

    updateHopsCount(value) {
      if (value === -1 && this.noOfHops === 0) {
        return;
      }
      this.noOfHops += value;
    },

    toggleSection(section) {
      this.activeSection = this.activeSection !== section ? section : null;
    },

    getConfirmedNode(graphData) {
      const confirmNode = graphData.filter(
        (item) => item.d?.node_status == "confirmed"
      );
      const confirmedNodeIds = confirmNode.map((item) => item.id);
      return confirmedNodeIds;
    },

    async arrangeChart() {
      //const source = this.getSourcesNode();
      // const confirmedId = this.getConfirmedNode(this.graphData);
      if(this.chart){
        await this.chart.layout("organic", {
          animate:false,
          consistent:true,
          latten:true,
          spacing: 'equal',
          // orderBy:{
          //   property:"node_type",
          //   sortBy:"ascending",
          // },
          tightness: 5,
          time: this.chartData.time,
          mode: this.chartData.mode,
          easing: this.chartData.easing,
        });

        // await this.chart.arrange("radial", confirmedId, {
        //   fit: true,
        //   animate: true,
        //   position: "average",
        //   tightness: 3,
        // });
      }
    },

    //  first default selected filter
    setSelectedFilter(graphData){
      const modifiedTypes = graphData.map(node => (node.d?.node_type=== 'risk') ? 'risk record' : (node.d?.node_type === 'court') ? 'court record' : node.d?.node_type);
      const uniqueTypes = [...new Set(modifiedTypes)]; 
      this.selectedFilters.node_type = uniqueTypes.filter((item) => item != null); 
    },

    // for graph ready use klReady
    async klReady(chart) {
      const imageAlignment = {};

      // List of icons to realign
      const icons = Object.keys(imageAlignmentDefinitions);
      icons.forEach((icon) => {
        imageAlignment[KeyLines.getFontIcon(icon)] =
          imageAlignmentDefinitions[icon];
      });

      this.chart = chart;
      this.chart.options = ({
        drag: {
          links: false,
        },
        truncateLabels: { maxLength: 15 },
        imageAlignment,
        selectedNode: {
          b: "#111",
          bw: 5,
          fbc: "#333",
          fc: "white",
          oc: { b: "#222", bw: 5 },
        },
        selectedLink: {
          c: LINK_COLOR.CONFIRMED, // Color for selected link
          w: 5, // Width for selected link
        },
        //logo: { u: "/images/Logo.png" },
        iconFontFamily: "FontAwesome",
        linkEnds: { avoidLabels: false },
        minZoom: 0.02,
        handMode: true,
      });

      this.graph = KeyLines.getGraphEngine();
      // load the raw items in to the graph engine
      await this.graph.load(chart.serialize());
      this.$emit("KLgraphReady");
      await this.applyTheme();
      this.setUpEventHandlers(); // event apply on graph 
      this.onSelection();  // set up the initial look 
    },

     setUpEventHandlers(){
      this.chart.on('selection-change', this.onSelection);
      this.chart.on("double-click", (item) => {
        const entityData = this.keylineData.items.find((items) => {
          if (items.id == item.id) {
            return items.d.unique_identifier_id;
          }
        });
        if (entityData) {
          if(entityData.d.source == 'Search Engine'){
            this.sourceName = 'internet';
          }else{
            this.sourceName = entityData.d.source;
          }
             
          let payload = {
            source_name: entityData.d.source,
            unique_identifier_id: entityData.d.unique_identifier_id,
            case_id: this.caseId,
          };
          if( entityData.d.source == 'input'){
            delete payload.source_name
            delete payload.unique_identifier_id
            payload.query_ids = [`${entityData.d.unique_identifier_id}`]
            payload.main_entity = true;
            this.isMainenity = true;
          }
          this.getEntityData(payload,entityData.d.source);
          this.showCanvas = true;
        }
      });
     },

    async getEntityData(payload,sourceName) {
      let data = {} 
      if(sourceName == 'Search Engine'){
        data = await getinternetDataDetails(payload)
      }
      else{
        data =  await getEntityIdentifiers(payload);
      }
      this.individualData = data?.data?.data[0];
    },

    // data formate, add node and link  properties
    formatKeylinesData(chartRef) {
      const formattedNodes = [];
      const formattedLinks = [];
      if (chartRef) {
        chartRef.each({}, (item) => {
          if (item.type == "node") {
            let nodeColor = "";
            var fontIcon;
            var fontColor='';
            let nodeSize = 1;
            let risk = {};
            if (item.d.node_type == "company") {
              fontIcon = KeyLines.getFontIcon("fa-building");
            }
            if (item.d.node_type == "person") {
              fontIcon = KeyLines.getFontIcon("fa-user");
            }
            if (item.d.node_type == "country") {
              fontIcon = KeyLines.getFontIcon("fa-map-marked-alt");
            }
            if (item.d.node_type == "address") {
              fontIcon = KeyLines.getFontIcon("fa-address-card");
            }
            if (item.d.node_type == "news") {
              fontIcon = KeyLines.getFontIcon("fa-newspaper");
            }
            if (item.d.node_type == "risk") {
              fontIcon = KeyLines.getFontIcon("fa-exclamation-triangle");
              fontColor="#C70039";
            }
            if (item.d.node_type == "court") {
              fontIcon = KeyLines.getFontIcon("fa-balance-scale");
            }
            if (item.d.node_type == "internet") {
              fontIcon = KeyLines.getFontIcon("fa-globe");
            }
            
            if (item.d.node_type == "unknown" || !item.d?.node_type) {
              fontIcon = KeyLines.getFontIcon("fa-question");
            }

            if (item.d.node_status == "confirmed") {
              nodeColor = "#27AE60";
              nodeSize=2;
            }
            else{
              nodeColor=fontColor;
            }

            item.d["risks"] && item.d?.risks.length > 0
              ? risk = {  t: KeyLines.getFontIcon('fa-exclamation-triangle'), c: '#E74C3C', ff: "FontAwesome" }
              : "";

            formattedNodes.push({
              id: item.id,
              u: null,
              fi: { t: fontIcon, c: nodeColor, ff: "FontAwesome" },
              t: item.t,
              e: nodeSize,
              g: [
                  {
                    p: "ne",
                    fi: risk,
                    e: 1,
                  },
                ],
              //c: nodeColor, //background color
              ci:false,
              ls: "solid",
              sh:''  ,// shape like box
              d:item.d
            });
          }

          if (item.type == "link") {
            formattedLinks.push({
              id: item.id,
              c: this.getLinkColor(item),
              w: 3,
              a2:true,
              //a1: true,
            });
          }
        });
      }
      return { formattedNodes, formattedLinks };
    },

    getLinkColor(item) {
      if (item.d.relation_type == "confirmed") {
        return LINK_COLOR.CONFIRMED;
      }
      return LINK_COLOR.DEFAULT;
    },

    async applyTheme() {
      const formatedKeylinesData = this.formatKeylinesData(this.chart);
      if (formatedKeylinesData && this.chart) {
        // node styles
        this.chart.setProperties(formatedKeylinesData.formattedNodes);
        // link styles
        this.chart.setProperties(formatedKeylinesData.formattedLinks, true);

        await this.arrangeChart();
      }
    },
    async resetFilter(reset) {
      this.loading = true;

      if (reset == "filter") {
        this.$emit("graphUpdate");
        this.selectedFilters.node_type = DEFAULT_NODE_TYPES;
        this.selectedFilters.risk_only = false;
        this.filterApplied = false;
        await this.arrangeChart()
        await this.klReady(this.chart);
      }

      if (reset == "depth") {
        this.nodeConnectionCount = DEFAULT_CONNECTION_COUNT;
        this.updateKeylineData(this.firstGraphData);
        this.applyDepth = false;
        await this.klReady(this.chart);
      }

      if (reset == "combined") {
        this.selectedCombineByNodes = null;
        this.uncombineNodes();
        await this.klReady(this.chart);
        this.applyCombined = false;
      }

      this.loading = false;
    },
    async applyFilter(type) {
      this.layoutDone = true;

      this.loadingFilter = type == "filter";
      this.loadingDepth = type == "depth";

      this.$emit("graphUpdate", this.constructedPayload);

      if (this.constructedPayload.depth != DEFAULT_CONNECTION_COUNT && type == "depth") {
        this.applyDepth = true;
      } else {
        this.filterApplied = true;
      }

      this.loadingFilter = false;
      this.loadingDepth = false;

      await this.arrangeChart();
      await this.klReady(this.chart);

      this.layoutDone = false;
    },

    // combine by country

    async combineNodes() {
      this.layoutDone = true;
      this.loadingCombined = true;
      const toClose = [];
      const resultCombos = this.combineNodesByType();

      this.uncombineNodes();

      if (resultCombos) {
        // close all groups before we combine
        this.chart.combo().close(toClose, { animate: false });
        await this.chart.combo().combine(resultCombos, {
          animate: true, 
          time: 1500, 
          select: false,
          arrange: "concentric",
        });
      }

      this.arrangeChart();
      // reset foregrounded items when nodes are combined
      this.foregroundSelection([]);
      this.layoutDone = false;
      this.applyCombined = true;
      this.loadingCombined = false;
    },

    onSelection() {
      const selectedIds = this.chart.selection();
      // filter out any combo items to get only the underlying selection
      const ids = selectedIds.filter(id => !this.chart.combo().isCombo(id));
      // remove the combos from the selection 
      this.chart.selection(ids);
      // foreground the filtered selection of items and their connections
      this.foregroundSelection(ids);
    },

    foregroundSelection(ids) {
      if (ids.length === 0) {
        // restore all the elements in the foreground
        this.chart.foreground(() => true, { type: 'all' });
        // clear revealed items
        this.chart.combo().reveal([]);
      } else {
        // find the connections for all of the selected ids
        const neighbours = this.graph.neighbours(ids, { hops:this.noOfHops });
        const foregroundMap = {};
        const linksToReveal = [];
        const propsToUpdate = [];
        
        neighbours.links.forEach((linkId) => {
          // build map of neighbouring links to foreground
          foregroundMap[linkId] = true;
          // add neighbouring links to reveal array
          linksToReveal.push(linkId);
        });
        neighbours.nodes.forEach((nodeId) => {
          // add neighbouring nodes to foreground map
          foregroundMap[nodeId] = true;
        });
        
        const selectedItems = this.chart.getItem(ids);
        selectedItems.forEach((item) => {
          // add selected items to foreground map
          foregroundMap[item.id] = true;
          if (item.type === 'link') {
            // add only the selected links to the reveal array
            linksToReveal.push(item.id);
          }
        });

        // run foreground on underlying links and nodes
        this.chart.foreground(item => foregroundMap[item.id], { type: 'all' });

        // reveal the links
        this.chart.combo().reveal(linksToReveal);

        // background all combolinks
        this.chart.combo().find(linksToReveal, { parent: 'first' }).forEach((id) => {
          if (id !== null && this.chart.getItem(id).type === 'link') {
            propsToUpdate.push({ id, bg: true });
          }
        });

        this.chart.setProperties(propsToUpdate);
      }
    },

    combineNodesByType() {
      const groupedNodes = {};

      this.keylineData.items?.forEach((node) => {
        const nodeType = this.selectedCombineByNodes?.find(n => n === node.d.node_type);

        if (nodeType) {
          const key = node.d['label'].toUpperCase();

          if (key) {
            const id = node.id;
          
            if (!groupedNodes[key]) {
              groupedNodes[key] = {
                ids: [],
                label: node.d.label,
                style: {
                  e: 3,
                  c: "#fff",
                  fc: "#333",
                  fs: 20,
                  fi: {
                    t: KeyLines.getFontIcon(COMBINED_NODE_TYPE_TO_FA_ICON_MAP.get(nodeType)),
                    ff: "FontAwesome",
                  },
                  bw: 2,
                  b: 'rgb(150,150,150)',
                },
                openStyle: {
                  bw: 5,
                  b: "#c0c0c0",
                },
                open: false,
              };  
            }
            groupedNodes[key].ids.push(id.toString());
          }
        }
      });

      return this.cleanDuplicate(groupedNodes); 
    },

    cleanDuplicate(groupedNodes){
      var encounterId=[]; 
      const newGroupedNodes= groupedNodes;
      Object.keys(newGroupedNodes).forEach(key => {
        const arrayLength = newGroupedNodes[key].ids.length;
        if (arrayLength <= 1) {
          delete newGroupedNodes[key];
        }
      });
      
      return Object.values(newGroupedNodes).map((item)=>{ 
        if(item.ids){
          item.ids.forEach((id)=>{if(!encounterId.includes(id)){encounterId.push(id);}else{item.ids.splice(0,id)}})}
          return item;
        })
    },

    uncombineNodes() {
      const combos = [];
      this.chart.each({ type: "node", items: "toplevel" }, (node) => {
        if (this.chart.combo().isCombo(node.id)) {
          combos.push(node.id);
        }
      });

      if (combos.length) {
        this.chart
          .combo()
          .uncombine(combos, { full: true, select: false })
          .then(() => {
            this.chart.layout("organic", {
              time: 200,
              mode: "adaptive",
              easing: "linear",
            });
          });
      }
    },
  },
};
</script>
<style src="../graph.scss" scoped lang="scss"></style>
<style scoped lang="scss">

.unselectable {
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.active-btn {
  border: 1px solid var(--theme-color-main);
  background: var(--theme-color-main);
  color: #fff !important;
}

html {
  box-sizing: border-box;
}

.collapse_btn {
  display: flex;
  width: 43px;
  height: 38px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-top: 0.5px solid #e9e9ef;
  background: #fff;
}

.btn-color {
  border-radius: 2px;
  background: var(--theme-color-main);
}

.bg-drop {
  font-size: 16px;
  color: cornflowerblue;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}
.filter-item {
  margin-bottom: 10px;
}

.dot-mark {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;

  &--filter {
    top: 1px;
    left: 37px;
  }

  &--reload {
    top: 2px;
    right: 2px;
  }

  &--depth {
    top: 36px;
    left: 37px;
  }

  &--combined {
    top: 78px;
    left: 37px;
  }
}

.btn-wide {
  width: 70%;
}

.klchart {
  width: 100% !important;
  height: 1000px;
  overflow-y: hidden;
  font-family: 'Font Awesome 6 Brands' !important;
}


::v-deep {
  .offcanvasFilter .offcanvas-content {
    position: fixed;
    top: 0%;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    z-index: 9998;
    transition: box-shadow 0.3s ease;
    border-radius: 12px 0px 0px 12px;
    border: 0px solid var(--Input, #b9b9b9);
    box-shadow: -1px 0px 42px 0px rgba(0, 0, 0, 0.08);
  }
}
</style>
